<template>
	<div class="" id="pageTop">
		<div class="row my-3">
			<div class="h3">My Bookings</div>
		</div>
		<div class="row">
			<div class="col-md-8 mb-3">
				<input class="form-control" placeholder="Search..." v-model="txtSearch" />
			</div>
			<div class="col-8 col-sm-7 col-md-4 mb-3 order-first order-md-last">
				<select class="form-select" v-model="visibleTrips">
					<option value="upcoming-trips">Upcoming trips</option>
					<option value="6JV6TGnzJTPQwpnRRI3K">Trinidad Carnival</option>
					<option value="0Ekweq4tI4dVDQnqjuvr">Jamaica Carnival</option>
					<option value="Tq6KKFgh0PemVr6hbFpa">St Lucia Carnival</option>
					<option value="ZdL5MXF1fj6zqTuu27Vo">St Vincent Carnival</option>
					<option value="Mybpgts0tFetzAuacv8O">Barbados Crop Over</option>
					<option value="jUA5SSf09E4ONJc3IvEV">Grenada Spicemas</option>
					<option value="w0cAZ8GLWGJrNWmz0eDt">RI Convention</option>
					<option value="past-trips">Past trips</option>
					<option value="cancelled-customers">Cancelled</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="text-dark">
				Bookings: {{ searchResultsSummary }} / Group Size: {{ groupSize }} <br />Order
				Total: {{ formatCurrency(orderSummary.orderTotals) }} / Payments:
				{{ formatCurrency(orderSummary.orderPayments) }} /
				<span class="text-danger">Balance: {{ formatCurrency(orderSummary.orderBalance) }}</span>
			</div>
		</div>
		<div class="row my-4" v-if="Array.isArray(myOrders)">
			<div class="row my-5 text-secondary" align="center"
				v-if="Array.isArray(filteredOrders) && filteredOrders.length < 1">
				<em><i class="far fa-calendar"></i> No bookings found...</em>
			</div>
			<div class="col-12 mb-4 d-none d-lg-block">
				<table class="table table-bordered" v-if="filteredOrders.length > 0">
					<thead>
						<tr>
							<th>Booking Ref#</th>
							<th>Trip Details</th>
							<th>Travellers</th>
							<th>Total (USD)</th>
							<th>Balance (USD)</th>
							<th>Status</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="order in filteredOrders" :key="order.id">
							<td>
								<p>
									{{ order.ref }}
								</p>
							</td>
							<td>
								<p>
									{{ order.trip_event.name }}
									<br />
									<span class="text-muted small">{{ formatDate(order.trip_date?.start_date) }} to
										{{ formatDate(order.trip_date.end_date) }}</span>
									<br />
									<span class="text-muted small">{{ order?.accommodation?.name }}</span>
								</p>
							</td>
							<td>
								<p class="text-capitalize">
									{{ order.travellers.join(", ") }}
								</p>
							</td>
							<td>
								<p>
									{{ formatCurrency(order.order_total) }}
								</p>
							</td>
							<td>
								<p>
									{{
										formatCurrency(
											order.order_total - order.order_discount - order.order_payments
										)
									}}
								</p>
							</td>
							<td>
								<div class="dropdown">
									<button class="btn btn-sm dropdown-toggle small"
										:class="[`btn-${badgeColors[order.order_status]}`]" type="button"
										data-bs-toggle="dropdown" aria-expanded="false">
										{{ order?.order_status }}
									</button>
									<ul class="dropdown-menu">
										<li>
											<a class="dropdown-item" href="javascript: void(0);"
												v-show="order?.order_status !== 'in-review'"
												@click="btnUpdateStatusClick(order, 'in-review')">In Review</a>
										</li>
										<li>
											<a class="dropdown-item" href="javascript: void(0);"
												v-show="order?.order_status !== 'processing'"
												@click="btnUpdateStatusClick(order, 'processing')">Processing</a>
										</li>
										<li>
											<a class="dropdown-item" href="javascript: void(0);"
												v-show="order?.order_status !== 'cancelled'"
												@click="btnUpdateStatusClick(order, 'cancelled')">Cancelled</a>
										</li>
									</ul>
								</div>
							</td>
							<td>
								<div class="d-grid">
									<button class="btn btn-dark me-3" data-bs-toggle="modal"
										data-bs-target="#listMyBookingsModal" @click="btnViewClick(order)">
										View
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-12 col-sm-6 mb-4 d-lg-none" v-for="order in filteredOrders" :key="order.id">
				<ul class="list-group">
					<li class="list-group-item">
						<div class="row my-3">
							<div class="col-12" align="left">
								<small>
									<span class="badge bg-success text-uppercase small fw-normal" :class="order.order_status === 'in-review'
										? 'bg-warning'
										: order.order_status === 'cancelled'
											? 'bg-danger'
											: 'badge-success'
										">
										{{ order.order_status }}
									</span>
								</small>
							</div>
							<div class="col-12" align="left">
								<p>
									<span><small class="small text-secondary">Ref#:</small><br />
										{{ order.ref }}</span>
								</p>
								<p>
									<span><small class="small text-secondary">Trip:</small><br />
										{{ order.trip_event.name }}</span>
									<br /><span class="small">{{ formatDate(order.trip_date?.start_date) }} to
										{{ formatDate(order.trip_date.end_date) }}</span>
									<br />
									<span class="text-muted small">{{ order?.accommodation?.name }}</span>
								</p>
								<p>
									<span><small class="small text-secondary">Travellers:</small></span><br />{{
										order.travellers.join(", ") }}
								</p>
								<p>
									<span><small class="small text-secondary">Total (USD):</small></span><br />{{
										formatCurrency(order.order_total) }}
								</p>
								<p>
									<span><small class="small text-secondary">Balance (USD):</small></span><br />{{
										formatCurrency(
											order.order_total - order.order_discount - order.order_payments
										)
									}}
								</p>
							</div>
							<div class="col-12" align="left">
								<div class="dropdown">
									<button class="btn btn-sm dropdown-toggle small"
										:class="[`btn-${badgeColors[order.order_status]}`]" type="button"
										data-bs-toggle="dropdown" aria-expanded="false">
										{{ order?.order_status }}
									</button>
									<ul class="dropdown-menu">
										<li>
											<a class="dropdown-item" href="javascript: void(0);"
												v-show="order?.order_status !== 'in-review'"
												@click="btnUpdateStatusClick(order, 'in-review')">In Review</a>
										</li>
										<li>
											<a class="dropdown-item" href="javascript: void(0);"
												v-show="order?.order_status !== 'processing'"
												@click="btnUpdateStatusClick(order, 'processing')">Processing</a>
										</li>
										<li>
											<a class="dropdown-item" href="javascript: void(0);"
												v-show="order?.order_status !== 'cancelled'"
												@click="btnUpdateStatusClick(order, 'cancelled')">Cancelled</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-12" align="left">
								<button class="btn btn-dark my-3 col-4 me-3" data-bs-toggle="modal"
									data-bs-target="#listMyBookingsModal" @click="btnViewClick(order)">
									View
								</button>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="row my-4" v-else>
			<ul class="list-group mb-4" v-for="index in 3" :key="index">
				<li class="list-group-item">
					<div class="col-12 mb-4">
						<div class="row small">
							<div class="col-7 col-lg-3 my-1">
								<Skeletor height="25" pill />
							</div>
							<div class="col-7 col-lg-5 my-1">
								<Skeletor height="25" pill />
							</div>
							<div class="col-9 col-lg-2 my-1">
								<Skeletor height="25" pill />
							</div>
							<div class="col-12 col-lg-2 d-grid my-1">
								<Skeletor height="25" pill />
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div class="modal fade" id="listMyBookingsModal" tabindex="-1" aria-labelledby="listMyBookingsModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="listMyBookingsModalLabel">
							Trip Details
						</h5>
						<button type="button" class="btn-close m-1" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body py-1">
						<MyBookingsDetails :activeOrder="activeOrder" :activeOrderTravellers="activeOrderTravellers"
							:activeOrderPayments="activeOrderPayments" :key="bookingDetailsComponentKey" />
					</div>
					<!-- <div class="modal-footer">
            <div class="col-12 d-grid">
              <button
                class="btn btn-lg btn-dark"
                data-bs-toggle="offcanvas"
                data-bs-target="#addPaymentCanvas"
                aria-controls="addPaymentCanvas"
                v-if="
                  activeOrder.order_total >
                  activeOrder.order_discount + activeOrder.order_payments
                "
              >
                Make a payment
              </button>
            </div>
          </div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { orderBy } from "lodash";
import {
	collection,
	collectionGroup,
	where,
	query,
	onSnapshot,
	doc,
	updateDoc,
} from "firebase/firestore";
import { db, collections, subCollections } from "../../firebaseConfig";
import { formatDate, formatCurrency } from "../../utils";
import MyBookingsDetails from "./MyBookingsDetails.vue";

const notCancelled = ["in-review", "processing"];
const badgeColors = {
	"in-review": "warning",
	cancelled: "danger",
	processing: "success",
	completed: "success",
};

export default {
	name: "list-my-bookings",
	components: { MyBookingsDetails },
	computed: {
		searchResultsSummary() {
			const visibleTrips = this.visibleTrips;
			const prefix =
				visibleTrips == "upcoming-trips"
					? "Upcoming"
					: visibleTrips == "past-trips"
						? "Past"
						: visibleTrips == "cancelled-customers"
							? "Cancellations"
							: "All";
			return `${prefix} trips (${this.filteredOrders.length})`;
		},
		filteredOrdersByTrip() {
			const myOrders = Array.isArray(this.myOrders) ? this.myOrders : [];
			const visibleTrips = this.visibleTrips;
			const currentDate = Date.now();
			if (visibleTrips == "upcoming-trips") {
				return myOrders.filter(
					(order) => order.trip_date?.start_date + 10080000 > currentDate
				);
			}
			if (visibleTrips == "past-trips") {
				return myOrders.filter((order) => order.trip_date?.start_date < currentDate);
			}
			if (visibleTrips == "cancelled-customers") {
				return myOrders.filter((order) => order.order_status === "cancelled");
			}
			return myOrders.filter(
				(order) =>
					order.trip_event?.id === visibleTrips &&
					order.trip_date?.start_date > currentDate
			);
		},
		filteredOrders() {
			const filteredOrders = this.filteredOrdersByTrip.filter((order) => {
				if (this.visibleTrips == "cancelled-customers") {
					return !notCancelled.includes(order?.order_status);
				} else {
					return notCancelled.includes(order?.order_status);
				}
			});
			const txtSearch = this.txtSearch.toLowerCase();
			if (txtSearch.length < 3) {
				return filteredOrders;
			}
			return filteredOrders.filter((order) => {
				const ref = order.ref?.toString() || "";
				const tripDate = order.trip_date;
				const { travellers } = order;
				const search = txtSearch.toLowerCase();
				return (
					ref.includes(search) ||
					tripDate.name?.toLowerCase().includes(search) ||
					travellers.some((traveller) => traveller?.toLowerCase().includes(search))
				);
			});
		},
		groupSize() {
			return this.filteredOrders.reduce((acc, order) => acc + order.travellers.length, 0);
		},
		orderSummary() {
			let orderTotals = 0,
				orderPayments = 0;
			this.filteredOrders.forEach((order) => {
				if (order.order_status === "processing") {
					orderTotals += order.order_total - order.order_discount;
					orderPayments += order.order_payments;
				}
			});

			return { orderTotals, orderPayments, orderBalance: orderTotals - orderPayments };
		},
	},
	data() {
		return {
			formatCurrency,
			formatDate,
			badgeColors,
			myOrders: [],
			isBusy: false,
			bookingDetailsComponentKey: null,
			activeOrder: {},
			activeOrderTravellers: [],
			activeOrderPayments: [],
			visibleTrips: "upcoming-trips",
			txtSearch: "",
			orderStatus: [],
		};
	},
	methods: {
		async btnViewClick(order) {
			const orderId = order.id;
			this.activeOrder = order;
			this.activeOrderTravellers = await this.getActiveOrderTravellers(orderId);
			this.activeOrderPayments = await this.getActiveOrderPayments(orderId);
		},
		async btnUpdateStatusClick(order, newOrderStatus) {
			const orderRef = doc(db, `${collections.ORDERS}`, order.id);
			await updateDoc(orderRef, {
				order_status: newOrderStatus,
			});
		},
		getActiveOrderTravellers(orderId) {
			const travellers = [];
			const travellersRef = collection(
				db,
				`${collections.ORDERS}/${orderId}/${subCollections.TRAVELLERS}`
			);
			onSnapshot(
				travellersRef,
				(querySnapshot) => {
					travellers.length = 0;
					querySnapshot.forEach((doc) => {
						travellers.push({ id: doc.id, ...doc.data() });
					});
					this.bookingDetailsComponentKey = Date.now();
				},
				(error) => {
					console.log(error);
				}
			);
			return travellers;
		},
		getActiveOrderPayments(orderId) {
			const payments = [];
			const paymentsRef = query(
				collectionGroup(db, subCollections.JOURNAL_ENTRIES),
				where("order_id", "==", orderId)
			);
			onSnapshot(
				paymentsRef,
				(querySnapshot) => {
					payments.length = 0;
					querySnapshot.forEach((doc) => {
						payments.push({ id: doc.id, ...doc.data() });
					});
					this.bookingDetailsComponentKey = Date.now();
				},
				(error) => {
					console.log(error);
				}
			);
			return payments;
		},
	},
	mounted() {
		this.$store.commit("setPageTitle", "Bookings");
		const ordersRef = query(collection(db, collections.ORDERS));
		onSnapshot(
			ordersRef,
			(querySnapshot) => {
				let orders = [];
				querySnapshot.forEach((doc) => {
					orders.push({ id: doc.id, ...doc.data() });
				});
				this.myOrders = orderBy(orders, ["order_date"], ["desc"]);
			},
			(error) => {
				console.log(error);
			}
		);
	},
};
</script>
